import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StoreDataService {

	constructor() {
	}

	private data = {};

	sharedData: string;

	private Mail_address_type_ds = new BehaviorSubject<any>(null);
	mail_address_type = this.Mail_address_type_ds.asObservable();

	setOption(option, value): any {
		this.data[option] = value;
	}

	getOption(): any {
		return this.data;
	}
	setMail_address_type = (value: any) => {
		this.Mail_address_type_ds.next(value);
	}
}
