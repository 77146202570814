import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Identifiers } from '@angular/compiler';
const environmentUrl = environment.apiUrl;
@Injectable({
	providedIn: 'root'
})
export class GdmPomService {
	// http://10.5.6.34:5000
	posUrl = environmentUrl + '/forecast';
	indentList = environmentUrl + '/indent_list'
	// indentListDetails = environmentUrl + '/indent_list_details'
	indentListDetails = environmentUrl + '/get_pending_indent_details'
	indentNo = environmentUrl + '/get_pending_indent_cycle_count'
	indentListDate = environmentUrl + '/indent_list_date_details'
	submitevent = environmentUrl + '/indent_placed_submit'
	indentListStatus = environmentUrl + '/get_indent_placed'

	constructor(private http: HttpClient) { }


	getGdmPoslist(id: unknown): Observable<any> {
		// console.log(params);
		return this.http.get<any>(this.posUrl + '/' + id)
			.pipe(catchError(this.handleError));
	}

	getIndentList(storeId:number):any{
		return this.http.get(this.indentList + '/' + storeId)
			.pipe(catchError(this.handleError)
			);
	}
	getIndentListDetails(Id:number):any{
		return this.http.get(this.indentListDetails + '/' + Id)
			.pipe(catchError(this.handleError)
			);
	}
	getIndentNo(storeId:number):any{
		return this.http.get(this.indentNo + '/' + storeId)
			.pipe(catchError(this.handleError)
			);
	}
	getIndentDate(storeId:number):any{
		return this.http.get(this.indentListDate + '/' + storeId)
			.pipe(catchError(this.handleError)
			);
	}
	submitEvent(event: any): Observable<any> {
		// console.log(event);
		return this.http.post<any>(this.submitevent, event);
	}
	getIndentStatus(storeId:number):any{
		return this.http.get(this.indentListStatus + '/' + storeId)
			.pipe(catchError(this.handleError)
			);
	}
	private handleError(error): Observable<unknown> {// : HttpErrorResponse
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		return throwError(
			'Something bad happened; please try again later.');
	}

}
