import { StoreDataService } from './../../shared/service/store-data.service';
import { CustomLoader } from 'src/app/shared/service/custom-loader.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
	private individualConfig: Partial<IndividualConfig> = {
		// positionClass: 'toast-top-full-width',
		// progressBar: true,
		timeOut: 5000,
		progressBar: true,
		disableTimeOut: false,
		tapToDismiss: false,
		closeButton: true,
		enableHtml: true,
	};


	constructor(private toastr: ToastrService, private _dataService: StoreDataService,
		           private customLoader: CustomLoader, private router: Router) {

	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request)
			.pipe(
				catchError((error: HttpErrorResponse) => {
					let errorMsg = '';
					if (error.error instanceof ErrorEvent) {
						console.log('this is client side error');
						errorMsg = `Error: ${error.error.message}`;
						// alert(1);
						this.customLoader.stop();

						this.toastr.error(errorMsg, '', this.individualConfig);

					}
					else {
						console.log(error);

						if (error.status == 500) {
							errorMsg = ` ${error.error.message}`;
						} else if (error.status == 400) {
							errorMsg = ` ${error.error.result ? error.error.result.msg : error.error.msg}`;
						}
						else if (error.status == 401) {
							// auto logout if 401 response returned from api
							// this.authService.logout();
							// location.reload();
							this.router.navigate(['/']);
							errorMsg = ` ${error.error.result ? error.error.result.msg : error.error.msg}`;


						} else {
							if (error.status != 0) {
								console.log('this is server side error', error);
								// errorMsg = `Error Code: ${error.status},  Message: ${JSON.stringify(error.error.msg)}`;
								errorMsg = ` ${error.error.msg}`;
							}
							else {
								errorMsg = 'Sorry, Something went wrong. Kindly check with your IT Admin. Thank you!';
								// return throwError(errorMsg);
							}

						}
						this._dataService.setOption('disable', false);
						this.customLoader.stop();
						this.toastr.error(errorMsg, '', this.individualConfig);



					}
					console.log(errorMsg);
					return throwError(errorMsg);
				})
			);
	}
}
