<div class="admin-panel">
    <h4>Item Specific Promotion</h4>
    <div class="container-fluid">
        <form [formGroup]="itemPromoFrmGroup">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 event-sec">

                    <div class="row mt-n3">
                        <div class="col-lg-4 col-md-4 col-sm-12 mt-2">
                            <label for="exampleInputEmail1">Store Hierarchy <span class="mandatory">*</span></label>
                            <p-dropdown [options]="storeHierarchyLlist" [(ngModel)]="storeHierarchy" (onChange)="selectHierarchy($event)"
                                formControlName="store_hierarchy">
                            </p-dropdown>
                            <div class="invalid-err" *ngIf="submitted && hasError('store_hierarchy', 'required')">
                                Store hierarchy is required
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 mt-2" *ngIf="storeHierarchy!=='Store'">
                            <label for="exampleInputEmail1">Store Hierarchy Details <span
                                    class="mandatory">*</span></label>

                            <p-multiSelect [options]="storeHieDetails" [(ngModel)]="selectedStoreHierarchyDetails"
                                [resetFilterOnHide]="true" formControlName="store_hierarchy_details" optionLabel="name" id="sthd"
                                display="chip" [filter]="true" filterBy="name" placeholder="Select">
                            </p-multiSelect>
                            <div class="invalid-err"
                                *ngIf="submitted && hasError('store_hierarchy_details', 'required')">
                                Store hierarchy details is required
                            </div>
                        </div>

                        <div class="col-lg-2  mt-2" *ngIf="storeHierarchy=='Store'">
                            <label for="exampleInputEmail1">Store IDs <em class="fa fa-info-circle hand-cursor ml-2"
                                    (click)="op.toggle($event)"></em></label>
                            <a href="{{IpAdress}}/uploads/templates/StoreIdTemplate.csv" download="Store IDs Template">
                            <button type="button" class="dwnCSV" tooltipPosition="top" pTooltip="Export to CSV">
                                Download<span class="fa fa-download ml-2"></span>
                            </button></a>
                        </div>

                        <p-overlayPanel class="abc" #op [showCloseIcon]="true" [style]="{width: '260px'}">
                            <ng-template pTemplate="content">
                                <p>Incase of multiple stores to be uploaded, user can download the store hierarchy template. 
                                Keep the applicable stores only and upload the file back.</p>
                            </ng-template>
                        </p-overlayPanel>


                        <div class="col-lg-2 mt-2" *ngIf="storeHierarchy=='Store'">
                            <label for="exampleInputEmail1">Upload Store IDs <span class="mandatory">*</span></label>
                            <label for="fileInput" class="file btn file-upload custom-file-upload uploadmarket"
                                tooltipPosition="top" pTooltip="Upload CSV">
                                Upload<span class="fa fa-upload ml-2"></span>
                            </label>
                            <input type="file" name="file" enctype='multipart/form-data' id="fileInput"
                                [ngClass]="submitted && !hasValidator('uploaded_file')? 'invalid-err':''"
                                accept="{{accept_csv_extention}}" (change)="onFileChange($event)" />
                            <input type="hidden" name="fileHidden" formControlName="uploaded_file" />
                            <div class="invalid-err" *ngIf="submitted && hasError('uploaded_file', 'required')">Please upload store IDs file
                            </div>

                            <div *ngFor="let name of fileName;let i=index" class="d-inline file-pad-left">
                                <span class="file-namep mt-2 left" data-toggle="tooltip" data-placement="top"
                                    title="{{name}}" tooltipPosition="top">{{name.slice(0,15) +'..'}}</span>
                                <i class="fa fa-times-circle left cross-p hand-cursor" aria-hidden="true"
                                    (click)="removeFile(i,'file')"></i>
                            </div>
                            
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-lg-4 col-md-4 col-sm-12 mt-0">
                            <label for="exampleInputEmail1">Promotion Name <span class="mandatory">*</span></label>
                            <input type="text" class="form-control promoName" formControlName="promotion_name"
                                id="promoName" aria-describedby="" placeholder="Promotion Name">
                            <div class="invalid-err" *ngIf="submitted && hasError('promotion_name', 'required')">
                                Promotion name is required
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 col-sm-12 mt-0">
                            <label for="exampleInputEmail1">Promoted Menu Item Name <span class="mandatory">*</span></label>
                            <p-multiSelect [options]="menuItemsName" [(ngModel)]="menuName"
                                [resetFilterOnHide]="true" formControlName="menu_item_name" optionLabel="name"
                                display="chip" [filter]="true" filterBy="name" placeholder="Select a Promoted Menu Item">
                            </p-multiSelect>
                            <!-- <p-dropdown [options]="menuItemsName" [(ngModel)]="menuName" placeholder="Select a Promoted Menu Item" 
                            optionLabel="name" [filter]="true" filterBy="name" formControlName="menu_item_name" [showClear]="true">
                            </p-dropdown> -->
                            <!-- <input type="text" class="form-control sales-value" autocomplete="off" formControlName="menu_item_name" id="descid"
                                aria-describedby="" placeholder="Menu Item Name"
                                [ngClass]="submitted && !hasValidator('menu_item_name') ? 'invalid-err' : '' "> -->
                            <div class="invalid-err"
                                *ngIf="submitted && hasError('menu_item_name', 'required')"> Menu item name is required
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-lg-4 col-md-4 col-sm-12">
                          <div class="form-group">
                            <label for="exampleInputEmail1">Promotion Start Date <span class="mandatory">*</span></label>
            
                            <p-calendar name="support_start_date" id="promoStartDate" [minDate]="tomorrow" [showIcon]="true" placeholder="Promotion Start Date"
                              [ngClass]="submitted && !hasValidator('promotion_start_date') ? 'invalid-err' : '' "
                              formControlName="promotion_start_date" inputId="icon" dateFormat="dd/mm/yy" [(ngModel)]="promoDate">
                            </p-calendar>
                            <div class="invalid-err" *ngIf="submitted && hasError('promotion_start_date', 'required')">
                                Promotion start date is required
                            </div>
                          </div>
                        </div>
                      </div>

                    <div class="row mt-3">

                        <div #choose class="col-lg-4 col-md-4 col-sm-12 ">
                            <div class="form-group">
                                <label>Download Sales Data Template</label>
                                <!-- <button type="button" class="file btn btn-upload file-upload custom-file-upload" title="Download"
                                    [routerLink]="['/api/v1/download_template_item_promotion']" [queryParams]="{menu_item_names: 'pizza', start_date: '2023-05-29'}">
                                        Download <em class="fa fa-download ml-1"></em>
                                </button> -->

                                <button type="button" class="file btn btn-upload file-upload custom-file-upload" title="Download"
                                    (click)="downloadFile()">
                                        Download <em class="fa fa-download ml-1"></em>
                                </button>
                            </div>
                        </div>


                        <!-- <div #choose class="col-lg-4 col-md-4 col-sm-12 ">
                            <div class="form-group">
                                <label>Download Sales Data Template</label>
                                <button type="button" (click)="downloadFile()"
                                    class="file btn btn-upload file-upload custom-file-upload" title="Download">
                                        Download <em class="fa fa-download ml-1"></em>
                                    </button>
                            </div>
                        </div> -->


                        <div #choose class="col-lg-4 col-md-4 col-sm-12 ">
                            <div class="form-group ">
                                <label>Upload Sales Data<span class="mandatory">*</span></label>
                                <label for="fileInputForUploadSales" class="file btn btn-upload file-upload custom-file-upload">
                                    Choose File <span class="fa fa-upload ml-1"></span>
                                </label>
                                <input type="file" name="file" enctype='multipart/form-data' id="fileInputForUploadSales"
                                    [ngClass]="submitted && !hasValidator('sales_data_file')? 'invalid-err':''"
                                    accept="{{accept_xlsx_extention}}" (change)="onUploadSalesFileChange($event)" />
                                <input type="hidden" name="fileHidden" formControlName="sales_data_file" />
                                <div class="invalid-err" *ngIf="submitted && hasError('sales_data_file', 'required')">
                                    Please upload sales data file
                                </div>

                                <!-- <div *ngFor="let name of uploadSalesFileName;let i=index" class="d-inline file-pad-left">
                                    <span class="file-namep mt-2 left" data-toggle="tooltip" data-placement="top"
                                        title="{{name}}" tooltipPosition="top">{{name.slice(0,15) +'..'}}</span>
                                    <i class="fa fa-times-circle left cross-p hand-cursor" aria-hidden="true"
                                        (click)="removeUploadSalesFile(i,'uploadSalesfile')"></i>
                                </div> -->
                                <div *ngFor="let name of uploadSalesFileName;let i=index" class="d-inline file-pad-left">
                                    <span class="file-namep mt-2 left" data-toggle="tooltip" data-placement="top"
                                        title="{{name}}" tooltipPosition="top">{{name.slice(0,15) +'..'}}</span>
                                    <i class="fa fa-times-circle left cross-p hand-cursor" aria-hidden="true"
                                        (click)="removeUploadSalesFile(i,'uploadSalesfile')"></i>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 pr-0">
                    <button type="button" (click)="btnCancel()"
                        class="btn btn-secondary btn-sp float-right">Cancel</button>
                    <button type="submit" #btnsave class="btn btn-primary btn-sp mr-3 float-right" (click)="submit_itemPromoFrmGroup(itemPromoFrmGroup.value)">
                        Submit
                    </button>
                </div>
            </div>
        </form>

        <!-- Ingredient Replaced Table -->
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 view-event pl-0 pr-0">
                <h3 class="mt-4 mb-3">List of Item Specific Promotion</h3>
                <p-table #fix_dt [value]="itemPromoList" [scrollable]="true" scrollHeight="280px" [rows]="5"
                    [rowsPerPageOptions]="[5,10,100]" [paginator]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 13%;">Promotion Name</th>
                            <th style="width: 15%;">Promoted Menu Item</th>
                            <th style="width: 11%;">Start Date</th>
                            <th style="width: 11%;">End Date</th>
                            <th style="width: 9%;">Store Hierarchy</th>
                            <th class="" style="width: 16%;">Store Hierarchy Details</th>
                            <th class="">Sales Data</th>
                            <th style="width:12%;">Last Modified</th>
                            <th class="text-center" style="width: 7%;">Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td style="width: 13%;">{{rowData?.promotion_name}}</td>
                            <td style="width: 15%;">{{rowData?.menu_item_name}} </td>
                            <td style="width: 11%;">{{rowData?.start_date}} </td>
                            <td style="width: 11%;">{{rowData?.end_date}} </td>
                            <td style="width: 9%;">{{rowData?.store_hierarchy}}</td>
                            <td class="" style="width: 16%;" *ngIf="rowData?.store_hierarchy!=='Store'">
                                <span *ngIf="rowData?.store_hierarchy!=='Store'" style="word-wrap: break-word;">{{rowData.store_hierarchy_details}}</span>
                            </td>
                            <td class="text-center" style="width: 16%;" *ngIf="rowData?.store_hierarchy=='Store'">
                                <span type="button" class="icon-btn" pTooltip="Download">
                                    <a href="{{IpAdress}}/{{rowData?.uploaded_file}}"><em class="fa fa-download blue-color"></em></a>
                                </span>
                            </td>
                            <td class="text-center"  style="">
                                <span *ngIf="rowData?.sales_data_file" type="button" class="icon-btn" pTooltip="Download">
                                    <a href="{{IpAdress}}/{{rowData?.sales_data_file}}"><em class="fa fa-download  blue-color salesdwnldbtn"></em></a>
                                </span>
                            </td>
                            <td style="width:12%;">{{rowData?.last_modified}}</td>
                            <td class="text-center" style="width:7%;">
                                <span class="fa fa-trash-o red-color" (click)="deleteData(rowData?.id)" pTooltip="Delete"></span>
                            </td>                            
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>