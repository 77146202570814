import { AuthService } from '../../shared/service/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { IndividualConfig, ToastrService } from 'ngx-toastr';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	getErrorMessage: any;
	options = {
		timeOut: 30000
	};
	constructor(private authService: AuthService, private router: Router, private toasterService: ToastrService, ) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// add authorization header with jwt token if available
		const { url, method, headers, body } = request;
		const auth = this.authService.authValue;


		const refresh = this.authService.refreshValue;
		if (!window.navigator.onLine) {
			this.toasterService.error('Sorry, Please check your internet. Thank you!', null, {
				timeOut: 30000
			});
			return;
		} else {

			console.log(url.includes('/forecast/'));
			if (url.includes('/forecast/')) {
			}
			else {

				if (url.endsWith('/refresh')) {
					// alert(1);

					request = request.clone({
						setHeaders: {
							Authorization: `Bearer ${refresh}`
						}
					});
					// console.log(request)

				}
				else {
					// alert(2);
					// console.log(request)

					request = request.clone({
						setHeaders: {
							Authorization: `Bearer ${auth}`
						}
					});
				}



			}


			// if (auth) {
			//     request = request.clone({
			//         setHeaders: {
			//             Authorization: `Bearer ${auth}`
			//         }
			//     });
			// }
			//// console.log(JSON.stringify(request))
			return next.handle(request);

			// .pipe(
			//     catchError((error) => {
			//         //  //console.log('error is intercept')
			//         //  //console.log(error);
			//         return this.handleAuthErrorFromApi(error)
			//         //   return throwError(error.message);
			//     })
			// )
		}
	}
	private handleAuthErrorFromApi(err: HttpErrorResponse): Observable<any> {
		// //console.log('error status', JSON.stringify(err));
		this.getErrorMessage = err.error.msg;
		// console.log(this.getErrorMessage);
		// this.coreService.setError(this.getErrorMessage);
		// for token expired
		if (err.status === 401) {
			this.router.navigate(['']);
			return;
		}
		if (err.status === 500 || this.getErrorMessage === 'undefined' || this.getErrorMessage === undefined) {
			this.toasterService.error(err.statusText, null, {
				timeOut: 30000
			});
			return;

		}
		if (err.status !== 200 && err.status !== 201) {
			this.toasterService.error(this.getErrorMessage, null, {
				timeOut: 30000
			});
			return;

		}
	}
}
