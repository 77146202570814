import { PlatformLocation } from '@angular/common';
import { Injectable, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LocalService } from './local.service';


@Injectable({
    providedIn: 'root'
})
export class TenantService {
    home_state: boolean;
    objd: any;
    constructor(private localService: LocalService,
                private activatedRoute: ActivatedRoute, private router: Router,
                public platformLocation: PlatformLocation
    ) {
        // this.activatedRoute.queryParams.subscribe(params => {
        //     let date = params['k'];
        //     console.log(date); // Print the parameter to the console.
        // });
        // this.getTenant()


    }

    getTenant(): any {
        // const data_url = this.platformLocation.hash
        const is_pararam = this.platformLocation.hash.split('k=')[1];
        this.localService.setJsonValue('home_store', is_pararam);

        console.log(is_pararam);

        if (is_pararam && is_pararam != '' && is_pararam != undefined) {
            return false;

        }
        else {
            this.localService.setJsonValue('home_store', null);

            return true;
        }

    }
}
