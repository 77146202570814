import { StoreDataService } from './../service/store-data.service';
import { ToastrService } from 'ngx-toastr';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { first, take } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute, Params, RoutesRecognized, ParamMap } from '@angular/router';
import { UserCoreService } from '../service/core-service.service';
import { LocalService } from '../service/local.service';
import { GdmPomService } from '../service/gdm-pom.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
	ROLE_ID: any;
	store_id: any;
	Pos_enabled = false;
	AUTH_STORE_ID: any;
	user_icon = true;
	role_name: any;
	no_indent: any

	constructor(
		public authService: AuthService,
		private toastr: ToastrService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private dataser: StoreDataService,
		private localService: LocalService,
		private gdmPomService: GdmPomService

	) {

		console.log(this.store_id);

		// this.activatedRoute.paramMap.subscribe(params => {
		// 	console.log(params.get('id'));
		// 	this.store_id = params.get('id');
		//   });


		console.log(this.store_id);

		this.ROLE_ID = this.authService?.userInfo?.role_id;
		this.AUTH_STORE_ID = this.authService?.userInfo?.store_id;
		this.role_name = this.authService?.userInfo?.role_name;
		setTimeout(() => {
			this.ROLE_ID = this.authService?.userInfo?.role_id;
			this.AUTH_STORE_ID = this.authService?.userInfo?.store_id;
			this.role_name = this.authService?.userInfo?.role_name;

		}, 100);


		console.log(this.AUTH_STORE_ID);



		console.log(this.ROLE_ID);

	}
	ngAfterViewInit(): void {

		if (this.router.url.includes('/app/pos/')) {
			this.Pos_enabled = true;
			this.dataser.mail_address_type.pipe(take(1)).subscribe((addressid) => {
				this.store_id = addressid;

			});
		}
		console.log(this.Pos_enabled);
		console.log(this.store_id);

	}

	ngOnInit(): void {
		console.log( this.ROLE_ID!==10);
	if(this.ROLE_ID != 10 && this.ROLE_ID != 9){
		console.log(this.ROLE_ID);

		this.getIndentNumber();
		const is_pararam = this.localService.getJsonValue('home_store');
		if (is_pararam && is_pararam != '' && is_pararam != undefined) {
			this.user_icon = false;
		} else {
			this.user_icon = true;

		}
	}
	}
	getIndentNumber(): any {
		this.gdmPomService.getIndentNo(this.authService?.userInfo?.store_id)
			.subscribe((res: any) => {
				this.no_indent = res?.indent_count;
				console.log('Res', res);
			},
				err => {
					// this.customLoader.stop();
					// this.errorHandler.handleAuthErrorFromApi(err);
				},
				// () => {
				// 	this.customLoader.stop();
				// 	console.log('HTTP request completed.');
				// }
			);

	}
	btnIconClick(): any {
		// alert(1)
		if (this.ROLE_ID) {
			// admin
			if (this.ROLE_ID == 1) {
				this.router.navigate(['/app/admin/ingredient-replacement']);
			}
			// gdm
			else if (this.ROLE_ID == 4) {
				this.router.navigate(['/app/home']);
			}
			// MT
			else if (this.ROLE_ID == 5) {
				this.router.navigate(['/app/marketing/home']);
			}
			// bdm
			else if (this.ROLE_ID == 6) {
				this.router.navigate(['/app/business/store']);
			}
			// scc
			else if (this.ROLE_ID == 7) {
				this.router.navigate(['/app/scc/upload-file']);
			}
			// npd
			else if (this.ROLE_ID == 8) {
				this.router.navigate(['/app/npd/ingredient-replacement']);
			}
		}
	}
	logout(): void {
		this.authService.logout()
			.pipe(first())
			.subscribe(data => {
				// //console.log(data)
				if (data.message) {
					this.toastr.success('You are successfully logged out');
					this.router.navigate(['/']);
					this.router.navigate(['/login']);

				}
			});
	}


}
