import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [


	// { path: ':id', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
	// { path: '**', redirectTo: '' },


];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
