import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(items, searchTerm, arr): any {
    if (searchTerm) {
      const newSearchTerm = !isNaN(searchTerm) ? searchTerm.toString() : searchTerm.toString().toUpperCase(); return items.filter(item => {
        return this.lookForNestedObject(item, newSearchTerm, arr);
      });
    }
    else { return items; }
  }
  lookForNestedObject(firstItem, newSearchTerm, arr): any {
    const origItem = { ...firstItem };
    const that = this;
    let count = 0;
    parseNestedObject(firstItem);

    function parseNestedObject(item): any {
      for (const key in item) {
        // console.log(arr)
        if (arr.includes(key)) {
          // if (_.isPlainObject(item[key])) {
          //   if (origItem[key]) { delete origItem[key]; }
          //   parseNestedObject(item[key]);
          // }

        } else {
          if (_.isPlainObject(item[key])) {
            if (origItem[key]) { delete origItem[key]; }
            parseNestedObject(item[key]);
          }
          else if (Array.isArray(item[key])) {
            if (origItem[key]) { delete origItem[key]; }
            parseNestedObject(item[key]);
          }
          else {
            count++;
            if (origItem[key]) { delete origItem[key]; }
            origItem[key + count] = item[key];
          }
        }


      }
    }

    return that.search(firstItem, origItem, newSearchTerm);
  }
  search(item, origItem, newSearchTerm): any {
    const filteredList = [];
    let prop = '';
    for (const koy in origItem) {
      if (origItem[koy]) {
        prop = isNaN(origItem[koy]) ? origItem[koy].toString().toUpperCase() :
          origItem[koy].toString();
        if (prop.indexOf(newSearchTerm) > -1) {
          filteredList.push(item);
          return filteredList;
        }
      }
    }

  }
}
