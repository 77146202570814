import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ItemSpecificPromoService } from '../../../shared/service/admin service/item-specific-promo.service'
import { CustomLoader } from 'src/app/shared/service/custom-loader.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConstantService } from 'src/app/shared/service/constant.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-item-specific-promo',
  templateUrl: './item-specific-promo.component.html',
  styleUrls: ['./item-specific-promo.component.scss']
})
export class ItemSpecificPromoComponent implements OnInit {

  itemPromoFrmGroup: FormGroup
  storeHierarchyLlist = ["India", "Region", "Circle", "State", "Tier", "City", "Store"];
  submitted = false;
  storeHieDetails = [];
  selectedStoreHierarchyDetails;
  itemPromoList = [];
  accept_csv_extention: string;
  accept_xlsx_extention: string;
  uploadSalesFileName = [];
  fileName = [];
  storeHierarchy: string = 'India';
  IpAdress: any;
  menuItemsName = [];
  menuName = [];
  tomorrow: any;
  currentDate = new Date();
  promoDate= '';

  constructor(private fb: FormBuilder, private _itemSpecificPromoService: ItemSpecificPromoService, private customLoader: CustomLoader,
    private toastr: ToastrService, private commonService: CommonService, private _constantService: ConstantService, private router: Router) {

    this.itemPromoFrmGroup = this.fb.group({
      store_hierarchy: ['India', Validators.required],
      store_hierarchy_details: [''],
      uploaded_file: [''],
      promotion_name: ['', Validators.required],
      menu_item_name: ['', Validators.required],
      promotion_start_date: ['', Validators.required],
      sales_data_file: ['', Validators.required,],
      last_modified: ['']
    });
  }

  ngOnInit(): void {
    this.IpAdress = this.commonService.getIPAddress();
    this.accept_csv_extention = this._constantService.file_formats
    this.accept_xlsx_extention = this._constantService.accepted_file_formats
    this.tomorrow = new Date(this.currentDate.setDate(this.currentDate.getDate() + 1));
    this.menuItemName();
    this.get_itemPromoList();
    this.selectHierarchy({ value: 'India' })
  }

  onFileChange(event) {
    this.fileName = [];
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.itemPromoFrmGroup.get('uploaded_file').setValue(file);
      this.fileName.push(event.target.files[0].name);
    }
  }
  removeFile(index, type) {
    if (type == 'file') {
      this.itemPromoFrmGroup.get('uploaded_file').setValue('');
      this.fileName.splice(index, 1);
    }
    $('#fileInput').val('');
  }

  onUploadSalesFileChange(event) {
    this.uploadSalesFileName = [];
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.itemPromoFrmGroup.get('sales_data_file').setValue(file);
      this.uploadSalesFileName.push(event.target.files[0].name);
    }
  }

  removeUploadSalesFile(index, type): any {
    if (type == 'uploadSalesfile') {
      this.itemPromoFrmGroup.get('sales_data_file').setValue('');
      this.uploadSalesFileName.splice(index, 1);
    }
    $('#fileInputForUploadSales').val('');
  }

  selectHierarchy(event): void {
    let storeSelected = this.itemPromoFrmGroup.get('store_hierarchy').value;
    if (storeSelected !== 'Store') {
      this.itemPromoFrmGroup.get('store_hierarchy_details').setValidators([Validators.required]);
    } else {
      this.itemPromoFrmGroup.get('store_hierarchy_details').clearValidators();
      this.itemPromoFrmGroup.get('uploaded_file').setValidators([Validators.required]);
    }
    this.storeHieDetails = [];
    this.selectedStoreHierarchyDetails = [];
    this.itemPromoFrmGroup.get('store_hierarchy_details').reset();
    if (event.value == 'India') {
      this.storeHieDetails = [{ name: 'India' }];
      return;
    }
    const value = { "input_data": event.value };
    this._itemSpecificPromoService.hierarchyDetailsFilter(value).subscribe(resp => {
      if (resp && resp.output_data && resp.output_data.length > 0) {
        resp.output_data.forEach(store => {
          this.storeHieDetails.push({ name: store })
        });
      }
    });
  };

  menuItemName() {
    this._itemSpecificPromoService.getMenuMasterList().subscribe(resp => {
      if (resp && resp.result && resp.result.length) {
        resp.result.forEach(menuItem => {
          this.menuItemsName.push({ name: menuItem })
        });
      }
    });
  };

  submit_itemPromoFrmGroup(itemPromoValue) {
    console.log(itemPromoValue);

    this.submitted = true;
    if (this.itemPromoFrmGroup.invalid) return;
    const uploadItemPromoData = new FormData();
    uploadItemPromoData.append('store_hierarchy', itemPromoValue.store_hierarchy);
    if (itemPromoValue.store_hierarchy == 'Store') {
      uploadItemPromoData.append('store_hierarchy_details', 'file_download');
    } else {
      uploadItemPromoData.append('store_hierarchy_details', itemPromoValue.store_hierarchy_details.map(storeHieDetails => storeHieDetails.name).toString());
    }
    uploadItemPromoData.append('uploaded_file', itemPromoValue.uploaded_file);
    uploadItemPromoData.append('promotion_name', itemPromoValue.promotion_name);
    uploadItemPromoData.append('menu_item_name', itemPromoValue.menu_item_name.map(promoName => promoName.name).toString());
    uploadItemPromoData.append('promotion_start_date', moment(itemPromoValue.promotion_start_date).format('YYYY-MM-DD'))
    uploadItemPromoData.append('sales_data_file', itemPromoValue.sales_data_file);
    uploadItemPromoData.append('last_modified', moment().format('YYYY-MM-DD HH:mm'));

    this.customLoader.start();
    this._itemSpecificPromoService.postItemPromo(uploadItemPromoData).subscribe(resp => {
      this.toastr.success(resp.message);
      this.customLoader.stop();
      this.btnCancel();
      this.get_itemPromoList();
    });
  }
  get_itemPromoList() {
    this.customLoader.start();
    this._itemSpecificPromoService.getItemPromoList().subscribe((res: any) => {
      if (res) {
        this.itemPromoList = res?.result;
      }
      this.customLoader.stop();
    });
  };

  btnCancel(): void {
    this.itemPromoFrmGroup.reset();
    this.submitted = false;
    this.itemPromoFrmGroup.patchValue({ store_hierarchy: 'India' });
    this.selectHierarchy({ value: 'India' });
    this.fileName = [];
    this.uploadSalesFileName = [];
    this.menuName = [];
    this.promoDate = '';
  }
  deleteData(id) {
    Swal.fire({
      title: 'Stop! Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it '
    }).then(result => {
      if (result.isConfirmed == true) {
        this._itemSpecificPromoService.deleteItemPromo(id).subscribe((resp: any) => {
          if (resp) {
            // this.toastr.success(res.message);
            this.get_itemPromoList();
            Swal.fire(
              'Deleted!',
              'Your Record has been deleted.',
              'success'
            )
          }
        });
      }
    });
  };

  hasValidator(control: string): any {
    const validator = this.itemPromoFrmGroup.get(control);
    // console.log(!!validator.valid);
    return validator.valid;

  }
  hasError = (controlName: string, errorName: string) => {
    return this.itemPromoFrmGroup.controls[controlName].hasError(errorName);
  }

  // downloadFile(): void {
  //   const url: string = `${this.IpAdress}/uploads/templates/SalesDataTemplate-ISP.csv`;
  //   const fineName: string ="Sales Data Template - ISP.csv";
  //   this.commonService.downloadFile(url, fineName);
  // }

  downloadFile(): void {  
    if (this.menuName.length == 0 && this.promoDate == '') {
      window.alert('To Download Sales Data Template Please Enter Promoted Menu Item Name and Promotion Start Date')
    } else if (this.menuName.length == 0) {
      window.alert('To Download Sales Data Template Please Enter Promoted Menu Item Name')
    } else if (this.promoDate == '') {
      window.alert('To Download Sales Data Template Please Enter Promotion Start Date')
    } else {
      const menuName: string = this.menuName.map(ele => ele.name).toString();
      const selectedDate: string = moment(this.promoDate).format('YYYY-MM-DD');
      const url = `${this.IpAdress}/api/v1/download_template_item_promotion?menu_item_names=${menuName}&start_date=${selectedDate}`;
      const fineName: string = "Sales Data Template - ISP.csv";
      this.commonService.downloadFile(url, fineName);
    }
  }

}
