import { AuthService } from 'src/app/shared/service/auth.service';
import { CustomLoader } from './shared/service/custom-loader.service';
import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { NgxUiLoaderConfig } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { skip } from 'rxjs/operators';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
	NgXToastrService: ToastrService;

	title = 'jubilent-angular';
	config: NgxUiLoaderConfig = {};
	subscriber: Subscription;
	constructor(private customLoader: CustomLoader, private activatedRoute: ActivatedRoute,
		           private router: Router, private authService: AuthService) {
		// this.activatedRoute.queryParams.pipe(skip(2)).subscribe(params => {
		// 	const date = params['k'];
		// 	console.log(date)
		// })
		// this.activatedRoute.params.subscribe(params => {
		// console.log(params.k)

		// }
		// );

	}
	ngAfterViewInit(): void {
		this.subscriber = this.customLoader.config.subscribe(
			config => {
				(this.config = config);
				console.log(this.config);
			}
		);
	}
	ngOnInit(): void {


	}

	ngOnDestroy(): void {
		if (this.subscriber) {
			this.subscriber.unsubscribe();
		}
	}
}
