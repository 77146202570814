import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
	providedIn: 'root'
})
export class LocalService {
	constructor(private storageService: StorageService) { }
	// Set the json data to local storage
	setJsonValue(key: string, value: unknown): void{
	// console.log(key, value);
		this.storageService.secureStorage.setItem(key, value);
	}
	// Get the json value from local storage
	getJsonValue(key: string): any {

		return this.storageService.secureStorage.getItem(key);
	}
	// Clear the local storage
	clearToken(): void {
		return this.storageService.secureStorage.clear();
	}
}
