import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { Observable } from 'rxjs';

const environmentUrl = environment.apiUrl;


@Injectable({ providedIn: 'root' })
export class CommonService {

	inrCurrency = environmentUrl + '/change_currency_code'
	constructor(private http: HttpClient
	) {

	}

	getIPAddress(): any {
		return environmentUrl.split('/api/v1', 1);
	}
	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode == 101 || charCode == 45) {
			return false;
		}
		return true;
	}
	// prevent leading space
	leading_space(event: any): any {
		if (event.target.selectionStart === 0 && event.code === 'Space') {
			event.preventDefault();
		}
	}
	camel_convertion(strdata): any {
		const i = strdata

			// insert a space before all caps
			.replace(/([A-Z])/g, ' $1')
			// uppercase the first character
			.replace(/^./, function(str): any { return str.toUpperCase(); });
		return i;

	}
	// primeng sorting for complex datatype as date
	customSort(event: SortEvent): any {
		event.data.sort((data1, data2) => {
			let value1;
			let value2;
			let result = null;
			// this is only for multiple values in same column
			if (event.field === 'geography.0.geography_value') {
				if (data1.geography.length > 0) {
					value1 = data1.geography[0].geography_value;
				}
				if (data2.geography.length > 0) {

					value2 = data2.geography[0].geography_value;
				}
			}
			else {
				value1 = data1[event.field];
				value2 = data2[event.field];
			}
			if (value1 == null && value2 != null) {
				result = -1;
			}
			else if (value1 != null && value2 == null) {
				result = 1;
			}
			else if (value1 == null && value2 == null) {
				result = 0;
			}

			else if (typeof value1 === 'string' && typeof value2 === 'string') {
				const test = value1.substr(2, 1);
				const date1 = moment(value1, 'DD/MM/YYYY');
				const date2 = moment(value2, 'DD/MM/YYYY');
				if (test == '-' || test == '/') {
					result = date1.diff(date2, 'days');
				} else {
					result = value1.localeCompare(value2);
				}
				// result = value1.localeCompare(value2);

			}
			else {
				result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
			}

			return (event.order * result);
		});
	}

	scrollToTop(): void {
		window.scrollTo(0, 0);
		// window.scrollTo(0,500);
	}
	scrollToBottom(): void {
		window.scrollTo(0, 0);
		// window.scrollTo(0,500);
	}
	scrollToId(ele: HTMLElement): void {
		ele.scrollIntoView();
	}

	downloadFile(url: string, fileName: string): void {
		let element = document.createElement("a");
		document.body.appendChild(element);
		element.download = fileName;
		element.href = url;
		element.click();
		document.body.removeChild(element);
	}

	changeToInrDollarPound(currency): Observable<any>{
		return this.http.put<any>(this.inrCurrency, currency)
	}
}
