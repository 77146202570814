import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { NgxUiLoaderService, NgxUiLoaderConfig } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class CustomLoader {
  private _config: BehaviorSubject<NgxUiLoaderConfig>;
  config: Observable<NgxUiLoaderConfig>;
  config_setup: any =
    {
      bgsColor: '#009ad0',
      bgsOpacity: 0.5,
      bgsPosition: 'bottom-right',
      bgsSize: 60,
      bgsType: 'ball-spin-clockwise',
      blur: 14,
      delay: 0,
      fastFadeOut: true,
      fgsColor: '#009ad0',
      fgsPosition: 'center-center',
      fgsSize: 60,
      fgsType: 'ball-spin-clockwise',
      gap: 24,
      logoPosition: 'center-left',
      logoSize: 80,
      logoUrl: '',
      // "logoUrl": "../../../assets/images/logo.png",
      masterLoaderId: 'master',
      overlayBorderRadius: '0',
      overlayColor: 'rgba(40, 40, 40, 0.8)',
      pbColor: '#009ad0',
      pbDirection: 'ltr',
      pbThickness: 3,
      hasProgressBar: true,
      text: '',
      textColor: '#FFFFFF',
      textPosition: 'center-center'
      // "maxTime": -1,
      // "minTime": 300
    };

  constructor(public ngxUiLoaderService: NgxUiLoaderService) {
    this._config = new BehaviorSubject<NgxUiLoaderConfig>({});
    this.config = this._config.asObservable();
  }

  start(): any {
    this._config.next(this.config_setup);
    this.ngxUiLoaderService.start();
  }

  stop(): any {
    this.ngxUiLoaderService.stop();
  }
}
