import { TenantService } from './shared/service/tenant.service';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { Router } from '@angular/router';
import { MarketingActivitiesComponent } from './main/marketting/marketing-activities/marketing-activities.component';
import { ItemSpecificPromoComponent } from './main/marketting/item-specific-promo/item-specific-promo.component';

export function initSettings(injector: Injector, tenantService: TenantService): any {
	return () => new Promise<void>(resolve => {
		let routes = [];
		// alert(tenantService.getTenant())
		if (tenantService.getTenant()) {

			routes = [

				{ path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
				{ path: 'app', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
				{ path: '', redirectTo: '', pathMatch: 'full' }
			];
		} else {
			routes = [
				{ path: 'login', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
				{ path: 'app', loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
				{ path: '', redirectTo: 'app', pathMatch: 'full' }


			];
		}
		const router: Router = injector.get(Router);
		router.resetConfig(routes);
		resolve();
	});
}
@NgModule({
	declarations: [
		AppComponent,
		MarketingActivitiesComponent,
		ItemSpecificPromoComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		CoreModule
	],
	providers: [
		TenantService,
		{
			provide: APP_INITIALIZER,
			useFactory: initSettings,
			deps: [Injector, TenantService],
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
