import { Component, OnInit } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private commonService: CommonService,
    private authService: AuthService) { }

  ngOnInit(): void {
  }

  changeCurrency(currency: string) {
    const inr = document.getElementById('inr');

    if (currency == "INR") {
      inr.style.setProperty('background-color', '#ffc107');
    }
      const value = { "currency_code": currency.toString() }

      this.commonService.changeToInrDollarPound(value).subscribe(() => {
        this.authService.setCurrencyCode(currency);
      })

    }

  }
