<footer>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="row rowStyle">
                    <div class="col-lg col-md-3 col-sm-12">
                        <p class="poweredBy">Powered by</p>
                    </div>
                    <div class="col-lg col-md-3 col-sm-12">
                        <img src="../../../assets/images/RESENSE_LOGO.svg" width="80px" />
                    </div>
                    <div class="col-lg col-md-3 col-sm-12 vl"></div>

                </div>
            </div>

            <div class="col-lg-2 currency">
                <div class="row rowStyle"> 
                    <p class="footeroption"><i id="inr"class="fa fa-inr" style="cursor: pointer;" aria-hidden="true" (click)="changeCurrency('INR')">-INR |</i>&nbsp;<i class="fa fa-usd" style="cursor: pointer;" aria-hidden="true" (click)="changeCurrency('USD')">-Dollar |</i>&nbsp;<i class="fa fa-gbp" style="cursor: pointer; " aria-hidden="true" (click)="changeCurrency('GBP')">-Pound</i></p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-12">
                <div class="row rowStyle" style="gap: 15px;">
                    <!-- <div class="col-lg col-md-3 col-sm-12"> -->
                    <p class="footeroption">Privacy Policy</p>
                    <!-- </div> -->
                    <!-- <div class="col-lg col-md-3 col-sm-12"> -->
                    <p class="footeroption">Cookies Settings</p>
                    <!-- </div> -->
                    <!-- <div class="col-lg col-md-3 col-sm-12"> -->
                    <p class="footeroption">Terms of Use</p>
                    <!-- </div> -->

                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="row rowStyle" style="justify-content: left;padding-left: 40px;">
                    <p class="footeroption1">
                        Copyright © 2023 RESENSE
                    </p>
                </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-12">
                <div class="row rowStyle" style="gap: 5px;">
                    <img src="../../../assets/images/linkedin.svg" width="22px" />
                    <img src="../../../assets/images/Layer_1.svg" width="22px" />
                    <img src="../../../assets/images/Frame.svg" width="22px" />
                    <!-- 
                        <i class="fa fa-linkedin" aria-hidden="true"></i>
                        <i class="fa fa-twitter" aria-hidden="true"></i> -->
                    <!-- <i class="bi bi-facebook" aria-hidden="true"></i> -->
                    <!-- <i class="fa-brands fa-facebook"></i> -->
                    <!-- <i class="fa fa-facebook-square" aria-hidden="true"></i> -->
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="col-lg-3 col-md-3 col-sm-12">
            <h3<>CONTACT US</h3>
            <h4>Corporate Office</h4>
            <ul>
                <li><span class="mr-3 float-left"><em class="fa fa-map-marker"></em></span>
                    <p>5th Floor, Tower D, Plot No. 5,</p>
                    <p class="ml-4">Logix Techno Park, Sector 127,</p>
                    <p class="ml-4">Noida-201304, U.P. , India</p>
                </li> -->

    <!-- <li><span class="mr-3 float-left"><em class="fa fa-envelope-o"></em></span>
                    <p>guestcaredominos@jublfood.com</p>
                   
                </li> -->
    <!-- </ul>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12">
            <ul class="mt-5 ml-n4">
                <li class="mt-3 ml-n5"><span class="mr-3 float-left"><em class="fa fa-phone"></em></span>
                    <p>+91-120-4090500</p>
                   
                </li>
            </ul> -->
    <!-- <h3>ABOUT US</h3>
            <ul>
                <li><a  >About Us</a></li>
                <li><a >Privacy Policy</a></li>
                <li><a  >Terms & Conditions
                </a></li>
            </ul> -->
    <!-- </div>
        <div class="col-lg-3 col-md-3 col-sm-12"> -->
    <!-- <h3>FOLLOW US</h3>
            <ul>
            <li class="d-inline mr-3"><a  ><em class="fa fa-facebook"></em></a></li>
            <li class="d-inline mr-3"><a  ><em class="fa fa-twitter "></em></a></li>
            <li class="d-inline mr-3"><a  ><em class="fa fa-google-plus"></em></a></li>
        </ul> -->
    <!-- </div>
        <div class="col-lg-3 col-md-3 col-sm-12 text-right">
            All Rights Reserved © 2022.
        </div> -->

</footer>