<div class="container-fluid no-padding" ngxUiLoaderBlurred>
  <router-outlet></router-outlet>
</div>
<ngx-ui-loader [text]="config?.text" [fgsColor]="config?.fgsColor" [logoSize]="config?.logoSize"
  [logoUrl]="config?.logoUrl"></ngx-ui-loader>

<!-- [fgsColor]="config.fgsColor"
[fgsPosition]="config.fgsPosition"
[fgsSize]="config.fgsSize"
[fgsType]="config.fgsType"
[bgsColor]="config.bgsColor"
[bgsOpacity]="config.bgsOpacity"
[bgsPosition]="config.bgsPosition"
[bgsSize]="config.bgsSize"
[bgsType]="config.bgsType"
[gap]="config.gap"
[logoPosition]="config.logoPosition"
[logoSize]="config.logoSize"
[logoUrl]="config.logoUrl"
[overlayColor]="config.overlayColor"
[pbColor]="config.pbColor"
[pbDirection]="config.pbDirection"
[pbThickness]="config.pbThickness"
[hasProgressBar]="config.hasProgressBar"
[text]="config.text"
[textColor]="config.textColor"
[textPosition]="config.textPosition" -->