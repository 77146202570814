import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const envUrl = environment.apiUrl

@Injectable({
	providedIn: 'root'
})
export class MarketingActivitiesService {

	getMarketActivities = envUrl + '/get_marketing_activities';
	storeMarketActivities = envUrl + '/insert_marketing_activities';
	hierarchyDetails = envUrl + '/store_hierarchy_filter'
	deleteMarketActivitiesList = `${envUrl}/delete_marketing_activities`

	constructor(private http: HttpClient) { }


	getMarketActivitiesList(): Observable<any> {
		return this.http.get<any>(this.getMarketActivities)
			.pipe(catchError(this.handleError));
	};

	postMarketActivities(data: any): Observable<any> {
		return this.http.post<any>(this.storeMarketActivities, data)
			.pipe(catchError(this.handleError));
	};

	hierarchyDetailsFilter(data: any): Observable<any> {
		return this.http.post<any>(this.hierarchyDetails, data)
			.pipe(catchError(this.handleError));
	};

	deleteMarketActivities(id: number): Observable<any>{
		return this.http.delete(this.deleteMarketActivitiesList + '/' + id)
		.pipe(catchError(this.handleError))
	};


	private handleError(error): Observable<unknown> {// : HttpErrorResponse
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		return throwError('Something bad happened; please try again later.');
	}

}
