<header>
  <nav class="navbar navbar-expand-lg navbar-light fristNav">

    <a class="navbar-brand"><img src="../../assets/images/logodefault.png" alt="alamar" (click)="btnIconClick()"></a>
    <div class="store-id" *ngIf="!(ROLE_ID === 5 || ROLE_ID === 1 || ROLE_ID===6 || ROLE_ID=== 7 || ROLE_ID === 8 || Pos_enabled || ROLE_ID === 10 || ROLE_ID === 9)">
      <!-- <em class="fa fa-bookmark-o"></em> -->
      <div class="Storeid">
        Store ID:
        <span style="font-weight: 700;">
          {{authService?.userInfo?.store_id}}
        </span>
      </div>
    </div>
    <div class="store-id" *ngIf="ROLE_ID === 9">
      <!-- <em class="fa fa-bookmark-o"></em> -->
      <div class="Storeid">
        Warehouse ID:
        <span style="font-weight: 700;">
          {{authService?.userInfo?.username}}
        </span>
      </div>
    </div>
    <div class="store-id" *ngIf="Pos_enabled">
      <div class="Storeid">
        Store ID:
        <span style="font-weight: 700;">
          {{authService?.userInfo?.store_id}}
        </span>
      </div>
    </div>
    <!-- <div class="vertical"></div> -->
    <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> -->


    <div class="collapse navbar-collapse" id="navbarSupportedContent" style="justify-content: end;">
      <!-- for admin login -->
      <ul class="navbar-nav" *ngIf="ROLE_ID ==1">
        <!-- <li class="nav-item" title="Home" class="power-bi">
          <a [routerLink]="['/app/admin/powerBi']" routerLinkActive="active"><span></span> Dashboards</a>
        </li> -->
        <li class="dropdown">
          <a [ngClass]="user_icon?'dropdown-toggle':''" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <em class="fa fa-user-circle-o"></em>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="user_icon">
            <!-- <a class="dropdown-item">User</a> -->
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
      <!-- for scc login to get logout-->
      <ul class="navbar-nav" *ngIf="ROLE_ID ==7">
        <li class="dropdown">
          <a [ngClass]="user_icon?'dropdown-toggle':''" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <em class="fa fa-user-circle-o"></em>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="user_icon">
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>

      <!-- for npd login to get logout-->
      <ul class="navbar-nav" *ngIf="ROLE_ID == 8 ">
        <!-- <li class="nav-item" title="Home">
          <a [routerLink]="['/app/npd/ingredient-replacement']" routerLinkActive="active">Ingredient Replacement</a>
        </li>
        <li class="nav-item" title="Home">
          <a [routerLink]="['/app/npd/new-menu-intro']" routerLinkActive="active">New Menu Introduction</a>
        </li> -->
        <li class="dropdown">
          <a [ngClass]="user_icon?'dropdown-toggle':''" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <em class="fa fa-user-circle-o"></em>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="user_icon">
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>


      <!-- FOR GDM and superadmin -->

      <ul class="navbar-nav" *ngIf="!ROLE_ID||ROLE_ID ==4 || ROLE_ID ==2">
        <!-- <li class="nav-item" title="Home">
          <a [routerLink]="['/app/home']" routerLinkActive="active">Home</a>
        </li>

        <li class="nav-item" title="Enter Events">
          <a [routerLink]="['/app/enter-event']" routerLinkActive="active">Enter Events</a>
        </li>
        <li class="nav-item" title="Place Indent" *ngIf="this.AUTH_STORE_ID == 'DPI63815'">
          <a [routerLink]="['/app/pos/' + this.AUTH_STORE_ID]" routerLinkActive="active">Place Indent</a>
        </li>
        <li class="nav-item" title="View Indents">
          <a [routerLink]="['/app/view-indents']" routerLinkActive="active">View Indents</a>
        </li> -->
        <li class="dropdown">
          <a [ngClass]="user_icon?'dropdown-toggle':''" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!-- <em class="fa fa-user-circle-o"></em> -->
            <div class="dropdownTitlte">
              <em class="fa fa-user-circle-o"></em>
              <p class="profile">
                Welcome <br /><b style="font-size: 12px; font-weight: bold; color: #2B6696;">
                  {{authService?.userInfo?.first_name}}
                  <!-- Jacques Bermon -->
                </b><br />Emp ID: {{authService?.userInfo?.last_name}}
              </p>
            </div>
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="user_icon">
            <!-- <a class="dropdown-item">User</a> -->
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
      <ul class="navbar-nav" *ngIf="ROLE_ID ==10">
        <!-- <li class="nav-item" title="Home">
          <a [routerLink]="['/app/home']" routerLinkActive="active">Home</a>
        </li>

        <li class="nav-item" title="Enter Events">
          <a [routerLink]="['/app/enter-event']" routerLinkActive="active">Enter Events</a>
        </li>
        <li class="nav-item" title="Place Indent" *ngIf="this.AUTH_STORE_ID == 'DPI63815'">
          <a [routerLink]="['/app/pos/' + this.AUTH_STORE_ID]" routerLinkActive="active">Place Indent</a>
        </li>
        <li class="nav-item" title="View Indents">
          <a [routerLink]="['/app/view-indents']" routerLinkActive="active">View Indents</a>
        </li> -->
        <li class="dropdown">
          <a [ngClass]="user_icon?'dropdown-toggle':''" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!-- <em class="fa fa-user-circle-o"></em> -->
            <div class="dropdownTitlte">
              <em class="fa fa-user-circle-o"></em>
              <p class="profile">
                Welcome <br /><b style="font-size: 12px; font-weight: bold; color: #2B6696;">
                  {{authService?.userInfo?.first_name}}
                
                </b><br />Emp ID: {{authService?.userInfo?.last_name}}
              </p>
            </div>
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="user_icon">
            <!-- <a class="dropdown-item">User</a> -->
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>

      <ul class="navbar-nav" *ngIf="ROLE_ID ==9">
        <!-- <li class="nav-item" title="Home">
          <a [routerLink]="['/app/home']" routerLinkActive="active">Home</a>
        </li>

        <li class="nav-item" title="Enter Events">
          <a [routerLink]="['/app/enter-event']" routerLinkActive="active">Enter Events</a>
        </li>
        <li class="nav-item" title="Place Indent" *ngIf="this.AUTH_STORE_ID == 'DPI63815'">
          <a [routerLink]="['/app/pos/' + this.AUTH_STORE_ID]" routerLinkActive="active">Place Indent</a>
        </li>
        <li class="nav-item" title="View Indents">
          <a [routerLink]="['/app/view-indents']" routerLinkActive="active">View Indents</a>
        </li> -->
        <li class="dropdown">
          <a [ngClass]="user_icon?'dropdown-toggle':''" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <!-- <em class="fa fa-user-circle-o"></em> -->
            <div class="dropdownTitlte">
              <em class="fa fa-user-circle-o"></em>
              <p class="profile">
                Welcome <br /><b style="font-size: 12px; font-weight: bold; color: #2B6696;">
                  {{authService?.userInfo?.first_name}}
                
                </b><br />Emp ID: {{authService?.userInfo?.last_name}}
              </p>
            </div>
          </a>

          <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="user_icon">
            <!-- <a class="dropdown-item">User</a> -->
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
      <!-- for marketting login -->
      <ul class="navbar-nav" *ngIf="ROLE_ID ==5">

        <!-- // Currently client is not using this 4 tabs thats why hide these tabs from UI -->

        <!-- <li class="nav-item" title="Home">
          <a [routerLink]="['/app/marketing/home']" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item" title="Enter Events">
          <a [routerLink]="['/app/marketing/event']" routerLinkActive="active">Enter Events</a>
        </li> -->
        <!-- <li class="nav-item" title="Enter Promotions">
          <a [routerLink]="['/app/marketing/promotion']" routerLinkActive="active">Enter Promotions</a>
        </li>
        <li class="nav-item" title="New Menu Item">
          <a [routerLink]="['/app/marketing/new-menu']" routerLinkActive="active">New Menu Item</a>
        </li> -->
        <!-- <li class="nav-item" title="Enter Promotions">
          <a [routerLink]="['/app/marketing/marketing-activities']" routerLinkActive="active">Boosting Sales</a>
        </li>
        <li class="nav-item" title="New Menu Item">
          <a [routerLink]="['/app/marketing/item-promotion']" routerLinkActive="active">Item Specific Promotion</a>
        </li> -->

        <li class="dropdown">
          <a [ngClass]="user_icon?'dropdown-toggle':''" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <em class="fa fa-user-circle-o"></em>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="user_icon">
            <!-- <a class="dropdown-item">User</a> -->
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
      <!-- FOR BDM(store manager) and superadmin -->
      <ul class="navbar-nav" *ngIf="ROLE_ID ==6 || ROLE_ID ==2">
        <!-- 
        <li class="nav-item" title="New store/Split store">
          <a [routerLink]="['/app/business/new-store-opening']" routerLinkActive="active">New Store Opening</a>
        </li> -->
        <!-- <li class="nav-item" title="New store/Split store">
          <a [routerLink]="['/app/business/store']" routerLinkActive="active">New store/Split store</a>
        </li> -->
        <li class="dropdown" *ngIf="ROLE_ID !=2">
          <a [ngClass]="user_icon?'dropdown-toggle':''" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <em class="fa fa-user-circle-o"></em>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="user_icon">
            <!-- <a class="dropdown-item">User</a> -->
            <a class="dropdown-item" (click)="logout()">Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <div class="secondNav">
    <!-- <div class="container"> -->
    <nav class="navbar navbar-expand-lg ">
      <ul class="navbar-nav" *ngIf="ROLE_ID == 8 ">
        <li class="nav-item" title="Home">
          <a [routerLink]="['/app/npd/ingredient-replacement']" routerLinkActive="active">Ingredient Replacement</a>
        </li>
        <li class="nav-item" title="Home">
          <a [routerLink]="['/app/npd/new-menu-intro']" routerLinkActive="active">New Menu Introduction</a>
        </li>
      </ul>
      <ul class="navbar-nav" *ngIf="!ROLE_ID||ROLE_ID ==4 || ROLE_ID ==2">
        <li class="nav-item" title="Home" class="home">
          <a [routerLink]="['/app/home']" routerLinkActive="active">
            <img src="../../assets/images/homeBlue.svg" width="15px" />
            Home</a>
        </li>

        <li class="nav-item" title="Place Indent" class="indent" *ngIf="no_indent > 0"
          style="background-color: #FFEFC6;">
          <a [routerLink]="['/app/pos/' + this.AUTH_STORE_ID]" routerLinkActive="active">
            <img src="../../assets/images/box.svg" width="15px" />
            Indents
            <span class="badge badge-pill badge-warning" style="color: white;">{{no_indent}}</span>
          </a>
        </li>
        <li class="nav-item" title="Place Indent" class="indent" *ngIf="no_indent == 0">
          <a [routerLink]="['/app/pos/' + this.AUTH_STORE_ID]" routerLinkActive="active">
            <img src="../../assets/images/box.svg" width="15px" />
            Indents
            <!-- <span class="badge badge-pill badge-warning" style="color: white;">{{no_indent}}</span>\ -->
          </a>
        </li>
        

        <li class="nav-item" title="Enter Events" onclick="" class="event">
          <a [routerLink]="['/app/enter-event']" routerLinkActive="active">
            <img src="../../assets/images/calendar.svg" width="15px" />
            Hyper Local Events</a>
        </li>
        <!-- <li class="nav-item" title="" onclick="" class="event">
          <a [routerLink]="['/app/RequestIST']" routerLinkActive="active">
            <img src="../../assets/images/calendar.svg" width="15px" />
            Request IST</a>
        </li> -->
       
 
      
      
        <!-- *ngIf="this.AUTH_STORE_ID == 'DPI63815'"  -->
        

        <!-- <li class="nav-item" title="View Indents" class="report">
              <a [routerLink]="['/app/view-indents']" routerLinkActive="active">
                <img src="../../assets/images/report.svg" width="15px"/>
                Reports</a>
            </li> -->
      </ul>
      <ul class="navbar-nav" *ngIf="ROLE_ID == 10">
        <li class="nav-item" title="home2" onclick="" class="home2">
          <a [routerLink]="['/app/demand']" routerLinkActive="active">
            <img src="../../assets/images/homeBlue.svg" width="15px" />
            Home</a>
        </li>
        <li class="nav-item" title="events" onclick="" class="event2">
          <a [routerLink]="['/app/demand/vendorindent']" routerLinkActive="active">
            <img src="../../assets/images/calendar.svg" width="15px" />
            Vendor/Tender Indents</a>
        </li>
        </ul>
        <ul class="navbar-nav" *ngIf="ROLE_ID ==9">
          <li class="nav-item" title="home3" onclick="" class="home3">
            <a [routerLink]="['/app/warehouse']" routerLinkActive="active">
              <img src="../../assets/images/homeBlue.svg" width="15px" />
              Home</a>
          </li>
          <li class="nav-item" title="Enter Events" onclick="" class="storewiseblue" style="background-color: #FFEFC6;">
            <a [routerLink]="['/app/warehouse/storewiseindents']" routerLinkActive="active">
              <img src="../../assets/images/StoreWiseBlue.svg" width="15px" />
              Store Wise Indents<span class="badge badge-pill badge-warning" style="color: white; margin-left: 6px;">1</span></a>
          </li>
          <li class="nav-item" title="Enter Events" onclick="" class="event">
            <a [routerLink]="['/app/warehouse/vendorwiseindents']" routerLinkActive="active">
              <img src="../../assets/images/VendorWise.svg" width="15px" />
              Vendor Wise Indents</a>
          </li>
          <!-- <li class="nav-item" title="Enter Events" onclick="" class="event">
            <a [routerLink]="['/app/warehouse/istapproval']" routerLinkActive="active">
              <img src="../../assets/images/VendorWise.svg" width="15px" />
              IST Approval</a>
          </li> -->
          </ul>
      <ul class="navbar-nav" *ngIf="ROLE_ID ==5">

        <!-- // Currently client is not using this 4 tabs thats why hide these tabs from UI -->

        <!-- <li class="nav-item" title="Home">
                <a [routerLink]="['/app/marketing/home']" routerLinkActive="active">Home</a>
              </li>
              <li class="nav-item" title="Enter Events">
                <a [routerLink]="['/app/marketing/event']" routerLinkActive="active">Enter Events</a>
              </li> -->
        <!-- <li class="nav-item" title="Enter Promotions">
                <a [routerLink]="['/app/marketing/promotion']" routerLinkActive="active">Enter Promotions</a>
              </li>
              <li class="nav-item" title="New Menu Item">
                <a [routerLink]="['/app/marketing/new-menu']" routerLinkActive="active">New Menu Item</a>
              </li> -->
        <li class="nav-item" title="Enter Promotions">
          <a [routerLink]="['/app/marketing/marketing-activities']" routerLinkActive="active">Boosting Sales</a>
        </li>
        <li class="nav-item" title="New Menu Item">
          <a [routerLink]="['/app/marketing/item-promotion']" routerLinkActive="active">Item Specific Promotion</a>
        </li>
      </ul>
      <ul class="navbar-nav" *ngIf="ROLE_ID ==6 || ROLE_ID ==2">

        <li class="nav-item" title="New store/Split store">
          <a [routerLink]="['/app/business/new-store-opening']" routerLinkActive="active">New Store Opening</a>
        </li>
      </ul>
    </nav>
    <!-- </div> -->
  </div>

</header>