// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://172.16.1.225:5000/api/v1'
   //apiUrl: 'http://13.200.76.41:8890/api/v1'
   apiUrl: 'https://demo.resense.tech/api/v1'
  //for production server

  //apiUrl: 'http://10.5.4.79:5000/api/v1',  // for dev server
  //apiUrlt: 'http://10.5.4.79:5000/api/v1'  // testing on dev server
  //apiUrlt: 'http://10.5.4.55:5001/api/v1'  // different port for prod server

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
