import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


const envUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ItemSpecificPromoService {

  hierarchyDetails = envUrl + '/store_hierarchy_filter';
  menuMasterList = envUrl + '/get_menu_master_list'
  insertItemPromo = envUrl + '/insert_item_specific_promotion'
  itemPromoList = envUrl + '/get_item_specific_promotion'
  ItemPromo = envUrl + '/delete_item_specific_promotion'

  constructor(private http: HttpClient) { }

  hierarchyDetailsFilter(data: any): Observable<any> {
		return this.http.post<any>(this.hierarchyDetails, data)
			.pipe(catchError(this.handleError));
	};

  getMenuMasterList(): Observable<any> {
		return this.http.get<any>(this.menuMasterList)
			.pipe(catchError(this.handleError));
	};

  postItemPromo(data: any): Observable<any> {
		return this.http.post<any>(this.insertItemPromo, data)
			.pipe(catchError(this.handleError));
	};

  getItemPromoList(): Observable<any> {
		return this.http.get<any>(this.itemPromoList)
			.pipe(catchError(this.handleError));
	};

  deleteItemPromo(id: number): Observable<any>{
		return this.http.delete(this.ItemPromo + '/' + id)
		.pipe(catchError(this.handleError))
	};



  private handleError(error): Observable<unknown> {// : HttpErrorResponse
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		return throwError('Something bad happened; please try again later.');
	}
}
