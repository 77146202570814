import { LocalService } from './local.service';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, Observable, Subscriber, throwError, Subscription } from 'rxjs';
import { catchError, retry, map, mergeMap, takeWhile } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';

import * as Base64 from 'crypto-js/enc-base64';
import { SHA256 } from 'crypto-js';
import { IndividualConfig } from 'ngx-toastr';
const environmentUrl = environment.apiUrl;

const message = 'welcome';
const nonce = 'k4Qnh4gDLzYjcMT8uptqc38zC';
const privateKey = 'jubl';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
	// public success_individualConfig: Partial<IndividualConfig> = {
	// 	// positionClass: 'toast-top-full-width',
	// 	// progressBar: true,
	// 	timeOut: 5000,
	// 	progressBar: true,
	// 	disableTimeOut: false,
	// 	tapToDismiss: false,
	// 	closeButton: true,
	// 	enableHtml: true,
	// };
	refreshTokenTimeout: any;
	encryptedPassword: any;


	userUrl = environmentUrl + '/me';
	resetEncrypt = environmentUrl + '/decrypt';
	resetDecrypt = environmentUrl + '/encrypt';
	public auth: Observable<any>;
	// private cookieDomain: string = '.tatva.dev2';
	private userSubject: BehaviorSubject<any>;
	public currencyCode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	fillForm = false;
	subscription$: Subscription;

	constructor(private http: HttpClient
		,          private localService: LocalService

	) {
		this.userSubject = new BehaviorSubject<any>(this.localService.getJsonValue('user-info'));

	}


	login(username: string, password: string, type?: any): Observable<any> {
		const check = (type == 'login2') ? this.http.post<any>(`${environmentUrl}/login2`, {
			username

		}) : this.http.post<any>(`${environmentUrl}/login`, {
			username,
			password,

		});
		return check.pipe(

			// catchError(this.handleError),
			map(response => {
				//// console.log(response);
				// login successful if there's a jwt token in the response
				if (response && response.access_token) {
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					// localStorage.setItem('auth', JSON.stringify(response));
					this.localService.setJsonValue('access_token', response.access_token);
					const jwtToken = JSON.parse(atob(response.access_token.split('.')[1]));
					console.log(JSON.stringify(jwtToken.exp) + ' ksjkdjsf');
					const expires = new Date(jwtToken.exp * 1000);
					console.log(expires + 'expires');
					const timeout = expires.getTime() - Date.now();
					console.log(timeout + 'timeout');
					console.log(new Date(timeout));
					this.localService.setJsonValue('refresh_token', response.refresh_token);
					this.refreshtokenTImer();

				}
				return response;
			}), mergeMap(() => {

				return this.http.get<any>(this.userUrl).pipe(
					map(response => {
						console.log(response)
						this.localService.setJsonValue('user-info', response.user_details);
						// localStorage.setItem('user-info', JSON.stringify(response.user_details));
						this.userSubject.next(response.user_details);
						localStorage.setItem('currency_Code', response.user_details.currency_code);

						return response;
					})
				);
			})

		);
	}
	public get userInfo(): any {
		return this.userSubject.value;
	}
	public get authValue(): any {
		//// console.log(this.authSubject.value);
		return this.localService.getJsonValue('access_token');
	}
	public get refreshValue(): void {
		//// console.log(this.authSubject.value);
		return this.localService.getJsonValue('refresh_token');
	}

	refreshtokenTImer(): void {
		// const inter = interval(30000)
		// this.subscription$ = inter.subscribe(x => {
		// 	this.refreshtoken().subscribe();
		// });
		console.log('timer started');
		this.refreshTokenTimeout = setTimeout(() => this.refreshtoken().subscribe(), 600000);
		console.log('timer stoped');

		// Every 14 minutes
		// this.refreshTokenTimeout = setTimeout(() => {
		// 	this.refreshtoken()	;
		// 	console.log('every 15 mints need tocall token');
		// }, 600000);
		console.log(this.refreshTokenTimeout);
		// 60000, 900000 ,840000--> 5 minute
	}
	refreshtoken(): any {
		// console.log('refresh token');
		// this.localService.setJsonValue('access_token', 'ela');
		return this.http.post<any>(`${environmentUrl}/refresh`, {}
		).pipe(

			// catchError(this.handleError),
			map(response => {
				if (response) {
					// Remove user details and jwt token in local storage
					// console.log(response);
					this.localService.setJsonValue('access_token', response.access_token);
					this.refreshtokenTImer();

				}
			}));
	}
	private stopRefreshTokenTimer(): void {
		clearTimeout(this.refreshTokenTimeout);
	}
	logout(): Observable<any> {
		return this.http.delete<any>(`${environmentUrl}/logout/access`).pipe(

			// catchError(this.handleError),
			map(response => {
				if (response) {
					// Remove user details and jwt token in local storage
					this.localService.clearToken();
					this.stopRefreshTokenTimer();

				}
				return response;
			}));
	}

	encryption(password): any {

		const hashDigest = SHA256(nonce + message);
		this.encryptedPassword = Base64.stringify(CryptoJS.HmacSHA512(password + hashDigest, privateKey));
		return this.encryptedPassword;

	}
	getEncryptEmail(params): any {
		// console.log(params);
		return this.http.get(this.resetEncrypt + '/' + params)
			.pipe(
				catchError(this.handleError)
			);
	}

	getdecryptEmail(params): Observable<any> {
		// console.log(params);
		return this.http.get(this.resetDecrypt + '/' + params)
			.pipe(
				catchError(this.handleError)
			);
	}
	ngOnDestroy(): void {
		console.log('cleared');
		if (this.subscription$) {

			this.subscription$.unsubscribe();
		}
	}

	private handleError(error): Observable<never> {// : HttpErrorResponse
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		return throwError(
			'Something bad happened; please try again later.');
	}

	setCurrencyCode(currencyCode: string) {
		localStorage.setItem('currency_Code', currencyCode);
		this.currencyCode.next(true);
	}

}
