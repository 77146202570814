import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { HttpErrorInterceptor } from './interceptor/error.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const providers = [
	{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpErrorInterceptor,
		multi: true
	}

];


@NgModule({
	declarations: [
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
	],
	exports: [
		BrowserModule,
		BrowserAnimationsModule,
	],
	providers: [providers]
})
export class CoreModule { }

