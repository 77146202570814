import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ConstantService {

	// accepted_file_formats = "image/*, .doc, .docx, .odt, .pdf, .xls, .xlsx, .ods, .ppt, .txt, .csv";
	accepted_file_formats = '.xlsx';
	file_formats = '.csv';
	adm_fileupload_formats = '.csv,.xls,.xlsx,.xlsm,.xlsb,.xlx';
	constructor() { }
	filtType: any = [
		// { id: 'store_scc_ingredient', name: 'Store-SCC - Ingradient Mapping' },
		{ id: 'indent_cycle', name: 'Indent Cycle' },
		{ id: 'ingredient_master', name: 'Ingredient Master' },
		{ id: 'variable_offset_factor', name: 'Variable Offset Factor' },
		{ id: 'forecast_analysis_report', name: 'Forecast Analysis Report' },
	];


	minutes: any = [
		{ id: '*', name: 'Every Minutes' },
		{ id: 'Every Other Minutes', name: 'Every Other Minutes' },
		{ id: '0', name: '0' }
	];

	hours: any = [
		{ id: '*', name: 'Every Hour' },
		{ id: 'Every Other Hour', name: 'Every Other Hour' },
		{ id: 'Every Six Hour', name: 'Every Six Hour' }
	];
	months: any = [
		{ id: '*', name: 'Every Month' },
		{ id: 'January', name: 'January' },
		{ id: 'February', name: 'February' }
	];
	Days: any = [
		{ id: '1', name: '1' },
		{ id: '2', name: '2' },
		{ id: '3', name: '3' }
	];
	weekDay: any = [
		{ id: '*', name: 'Every Weekday' },
		{ id: '0', name: 'Sunday' },
		{ id: '1', name: 'Monday' }
	];
}
