import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MarketingActivitiesService } from '../../../shared/service/admin service/marketing-activities.service';
import { CustomLoader } from 'src/app/shared/service/custom-loader.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { ConstantService } from 'src/app/shared/service/constant.service';
import { ngxCsv } from 'ngx-csv';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-marketing-activities',
  templateUrl: './marketing-activities.component.html',
  styleUrls: ['./marketing-activities.component.scss']
})
export class MarketingActivitiesComponent implements OnInit {

  marketActivitiesFrmGroup: FormGroup
  store_hierarchy_list = ["India", "Region", "Circle", "State", "Tier", "City", "Store"];
  submitted = false;
  storeHieDetails = [];
  selectedStoreHierarchyDetails;
  tomorrow = new Date();
  marketActivitiesList = [];
  accept_extention: string;
  storeHierarchy: string = 'India'
  fileName = [];
  uploadSalesFileName = [];
  IpAdress: any;

  constructor(private fb: FormBuilder, private marketActivitiesService: MarketingActivitiesService,
    private customLoader: CustomLoader, private toastr: ToastrService, private _constantService: ConstantService, private commonService: CommonService) {

    // this.marketActivitiesFrmGroup = this.fb.group({
    //   store_hierarchy: ['India', Validators.required],
    //   store_hierarchy_details: ['', Validators.required],
    //   date: ['', Validators.required],
    //   total_sales_value: ['',[
    //     Validators.required,
    //     Validators.pattern("^[+-]?([0-9]*[.])?[0-9]+")
    //   ]],
    // });

    this.marketActivitiesFrmGroup = this.fb.group({
      store_hierarchy: ['India', Validators.required],
      store_hierarchy_details: [''],
      uploaded_file: [''],
      promotion_name: ['', Validators.required],
      sales_data_file: ['', Validators.required],
      last_modified: ['']
    });
  }

  ngOnInit(): void {
    this.IpAdress = this.commonService.getIPAddress();
    this.tomorrow = new Date(this.tomorrow.setDate(this.tomorrow.getDate() + 1));
    this.get_marketActivitiesList();
    this.selectHierarchy({ value: 'India' })
    this.accept_extention = this._constantService.file_formats
  }

  onFileChange(event) {
    this.fileName = [];
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.marketActivitiesFrmGroup.get('uploaded_file').setValue(file);
      this.fileName.push(event.target.files[0].name);
      console.log(this.fileName);
    }
  }
  removeFile(index, type): any {
    if (type == 'file') {
      this.marketActivitiesFrmGroup.get('uploaded_file').setValue('');
      this.fileName.splice(index, 1);
    }
    $('#fileInput').val('');
  }

  onUploadSalesFileChange(event) {
    this.uploadSalesFileName = [];
    if (event.target.files.length > 0) {
      const uploadSalesFile = event.target.files[0];
      this.marketActivitiesFrmGroup.get('sales_data_file').setValue(uploadSalesFile);
      this.uploadSalesFileName.push(event.target.files[0].name);
    }
  }

  removeUploadSalesFile(index, type): any {
    if (type == 'uploadSalesfile') {
      // this.marketActivitiesFrmGroup.get('sales_data_file').setValue('');
      this.marketActivitiesFrmGroup.controls.sales_data_file.reset();
      this.uploadSalesFileName.splice(index, 1);
    }
    $('#fileInputForUploadSales').val('');
  }

  // for exporting json to csv file
  // exportCSV() {
  //   var options = {
  //     fieldSeparator: ',',
  //     quoteStrings: '"',
  //     decimalseparator: '.',
  //     showLabels: false,
  //     showTitle: false,
  //     title: 'Report',
  //     useBom: false,
  //     headers: ['Store ID']
  //   };
  //   new ngxCsv(this.storeHieDetails, "Store IDs", options)
  // }

  selectHierarchy(event): void {
    let storeSelected = this.marketActivitiesFrmGroup.get('store_hierarchy').value;
    if (storeSelected !== 'Store') {
      this.marketActivitiesFrmGroup.get('store_hierarchy_details').setValidators([Validators.required]);
    } else {
      this.marketActivitiesFrmGroup.get('store_hierarchy_details').clearValidators();
      this.marketActivitiesFrmGroup.get('uploaded_file').setValidators([Validators.required]);
    }
    this.storeHieDetails = [];
    this.selectedStoreHierarchyDetails = [];
    this.marketActivitiesFrmGroup.get('store_hierarchy_details').reset();
    if (event.value == 'India') {
      this.storeHieDetails = [{ name: 'India' }];
      return;
    }
    const value = { "input_data": event.value };
    this.marketActivitiesService.hierarchyDetailsFilter(value).subscribe(resp => {
      if (resp && resp.output_data && resp.output_data.length > 0) {
        resp.output_data.forEach(store => {
          this.storeHieDetails.push({ name: store })
        });
      }
    });
  }

  // submit_marketActivitiesForm(formValue): void {    
  //   this.submitted = true;
  //   if (this.marketActivitiesFrmGroup.invalid)
  //     return;
  //   const formPayload = formValue;
  //   formPayload.store_hierarchy_details = formPayload.store_hierarchy_details.map(storeHieDetails => storeHieDetails.name).toString();
  //   formPayload.date = moment(formPayload.date).format('YYYY-MM-DD');
  //   this.customLoader.start();
  //   this.marketActivitiesService.postMarketActivities(formPayload).subscribe(resp => {
  //     this.toastr.success(resp.message);
  //     this.customLoader.stop();
  //     this.btnCancel();
  //     this.get_marketActivitiesList();
  //   })
  // }

  submit_marketActivitiesForm(formValue) {
    console.log(formValue);
    this.submitted = true;
    if (this.marketActivitiesFrmGroup.invalid) return;
    const uploadMarketingData = new FormData();
    uploadMarketingData.append('store_hierarchy', formValue.store_hierarchy);
    if (formValue.store_hierarchy == 'Store') {
      uploadMarketingData.append('store_hierarchy_details', 'file_download');
    } else {
      uploadMarketingData.append('store_hierarchy_details', formValue.store_hierarchy_details.map(storeHieDetails => storeHieDetails.name).toString());
    }
    uploadMarketingData.append('uploaded_file', formValue.uploaded_file);
    uploadMarketingData.append('promotion_name', formValue.promotion_name);
    uploadMarketingData.append('sales_data_file', formValue.sales_data_file);
    uploadMarketingData.append('last_modified', moment().format('YYYY-MM-DD HH:mm'));

    this.customLoader.start();
    this.marketActivitiesService.postMarketActivities(uploadMarketingData).subscribe(resp => {
      this.toastr.success(resp.message);
      this.customLoader.stop();
      this.btnCancel();
      this.get_marketActivitiesList();
    });

  }

  btnCancel(): void {
    this.marketActivitiesFrmGroup.reset();
    this.submitted = false;
    this.marketActivitiesFrmGroup.patchValue({ store_hierarchy: 'India' });
    this.selectHierarchy({ value: 'India' });
    this.fileName = [];
    this.uploadSalesFileName = [];

  }

  get_marketActivitiesList() {
    this.customLoader.start();
    this.marketActivitiesService.getMarketActivitiesList().subscribe((res: any) => {
      if (res) {
        this.marketActivitiesList = res?.result;
      }
      this.customLoader.stop();
    });
  };

  deleteData(id) {
    Swal.fire({
      title: 'Stop! Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it '
    }).then(result => {
      if (result.isConfirmed == true) {
        this.marketActivitiesService.deleteMarketActivities(id).subscribe((res: any) => {
          if (res) {
            // this.toastr.success(res.message);
            this.get_marketActivitiesList();
            Swal.fire(
              'Deleted!',
              'Your Record has been deleted.',
              'success'
            )
          }
        });
      }
    });
  };


  hasValidator(control: string): any {
    const validator = this.marketActivitiesFrmGroup.get(control);
    // console.log(!!validator.valid);
    return validator.valid;

  }
  hasError = (controlName: string, errorName: string) => {
    return this.marketActivitiesFrmGroup.controls[controlName].hasError(errorName);
  }

  downloadFile() {
    const url = `${this.IpAdress}/uploads/templates/SalesDataTemplate.csv`;
    const fileName = 'Sales Data Template';
    this.commonService.downloadFile(url, fileName);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 101 || charCode === 45) {
      return false;
    }
    return true;
  }

  numberValidation(event: any): any {
    let k: any;
    k = event.charCode;
    console.log(k);
    return ((k >= 48 && k <= 57));
  }

}
