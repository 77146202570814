import { FilterPipe } from './validators/field.pipe';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ChartsModule } from 'ng2-charts';
import { TooltipModule } from 'primeng/tooltip';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgxPaginationModule } from 'ngx-pagination';
import { FileUploadModule } from 'primeng/fileupload';
import { NgPipesModule } from 'ngx-pipes';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CascadeSelectModule } from 'primeng/cascadeselect';

@NgModule({
	declarations: [HeaderComponent, FooterComponent, SidebarComponent, FilterPipe],
	imports: [
		CommonModule,
		RouterModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		BsDatepickerModule.forRoot(),
		ToastrModule.forRoot({
			preventDuplicates: true,
			timeOut: 5000,
			progressBar: true,
			disableTimeOut: false,
			tapToDismiss: false,
			closeButton: true,
			enableHtml: true,
		}),
		InputTextModule,
		CalendarModule,
		DropdownModule,
		InputTextareaModule,
		RadioButtonModule,
		TableModule,
		ButtonModule,
		DialogModule,
		ChartsModule,
		TooltipModule,
		MultiSelectModule,
		NgxPaginationModule,
		FileUploadModule,
		NgxUiLoaderModule,
		NgPipesModule,
		OverlayPanelModule,
		CascadeSelectModule
	],
	exports: [
		RouterModule,
		HttpClientModule,
		CommonModule,
		HeaderComponent,
		FooterComponent,
		SidebarComponent,
		FormsModule,
		ReactiveFormsModule,
		BsDatepickerModule,
		ToastrModule,
		InputTextModule,
		CalendarModule,
		DropdownModule,
		InputTextareaModule,
		RadioButtonModule,
		TableModule,
		ButtonModule,
		DialogModule,
		ChartsModule,
		TooltipModule,
		MultiSelectModule,
		NgxPaginationModule,
		FileUploadModule,
		NgxUiLoaderModule,
		NgPipesModule,
		FilterPipe,
		OverlayPanelModule,
		CascadeSelectModule
	],
	providers: [CookieService, DatePipe, FilterPipe]
})
export class SharedModule { }
