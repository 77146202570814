
<div class="sidebar">
    <h5>NAVIGATION</h5>
    <ul>

        <li><a title="Ingredient Replacement" [routerLink]="['/app/admin/ingredient-replacement']"  routerLinkActive="active"><em class="fa fa-refresh"></em> Ingredient Replacement </a></li>
        <li><a title="Marketing Activities" [routerLink]="['/app/admin/marketing-activities']"  routerLinkActive="active"><em class="fa fa-bar-chart"></em> Marketing Activities </a></li>
        <li><a title="New Store Opening" [routerLink]="['/app/admin/new-store']"  routerLinkActive="active"><em class=""><img src="../../../assets/images/new store open.png" height="17px" width="17px" alt=""></em> New Store Opening</a></li>
        <li><a title="Item Promotion" [routerLink]="['/app/admin/item-promotion']"  routerLinkActive="active"><em class="fa"><img src="../../../assets/images/item specific promo.png" height="17px" width="17px" alt=""></em> Item Specific Promotion</a></li>
        <li><a title="New Menu Introduction" [routerLink]="['/app/admin/new-menu-introduction']"  routerLinkActive="active"><em class="fa"><img src="../../../assets/images/new menu intro.png" height="15px" width="15px" alt=""></em> New Menu Introduction</a></li>


        <li><a title="Event Category" [routerLink]="['/app/admin/event-category']" routerLinkActive="active"><em class="fa fa-cubes"></em> Event Category</a></li>
        <li><a title="Scheduler" [routerLink]="['/app/admin/scheduler']"  routerLinkActive="active"><em class="fa fa-clock-o"></em> Scheduler </a></li>
        <li><a title="Manage Files" [routerLink]="['/app/admin/manage-file']"  routerLinkActive="active"><em class="fa fa-upload"></em> Manage Files </a></li>
        <li><a title="Configuration" [routerLink]="['/app/admin/configuration']"  routerLinkActive="active"><em class="fa fa-cog"></em> Configuration </a></li>
        <li><a title="Sales Order" [routerLink]="['/app/admin/sale-order']"  routerLinkActive="active"><em class="fa fa-line-chart"></em> Sales & Order Prediction </a></li>
        <li><a title="Report" [routerLink]="['/app/admin/report']"  routerLinkActive="active"><em class="fa fa-file-text-o"></em> Report </a></li>
        

        <!-- <li><a title="PowerBi Dashboard" [routerLink]="['/app/admin/powerBi']"  routerLinkActive="active"><em class="fa fa-file-text-o"></em> PowerBi Dashboard </a></li> -->
    </ul>
</div>
